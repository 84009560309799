import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Modal,
  CircularProgress,
} from "@mui/material";
import Drawer from "react-bottom-drawer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import veg from "../assets/veg.svg";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ShortTextIcon from "@mui/icons-material/ShortText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { placeOrder } from "../sevice/order";
import uuid from "react-uuid";
import MinOrderValueModal from "../components/cart/MinOrderValueModal";
import OrderPlacedSuccessModal from "../components/cart/OrderPlacedSuccessModal";
import {
  addToCart,
  clearCart,
  decreaseCart,
  removeFromCart,
} from "../store/cartslice";
import RemoveItem from "../components/common/RemoveItem";
import AddressModal from "../components/cart/AddressModal";
import Cookies from "js-cookie";
import CouponNotAvilable from "../components/common/CouponNotAvilable";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100vw",
    boxSizing: "border-box",
    padding: "0rem 0rem 2.5rem 0rem",
    backgroundColor: "rgba(248, 248, 248, 1)",
    position: "relative",
  },
}));

export default function Cart() {
  const { ourcart } = useSelector((state) => state);
  const { userid } = useSelector((state) => state);
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState(Object.fromEntries([...searchParams]));
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [gst, setGst] = useState(0);
  const [note, setNote] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("COD");
  const { admin } = useSelector((state) => state);

  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const orderModalHandleOpen = () => setOrderModalOpen(true);
  const orderModalHandleClose = () => setOrderModalOpen(false);

  useEffect(() => {
    findUserId();
  }, []);
  const findUserId = () => {
    if (!userid) {
      const id = uuid();
      dispatch({ type: "USERID", payload: { id: id } });
      Cookies.set("userid", JSON.stringify({ id: id }));
    }
  };

  useEffect(() => {
    setSubTotal(
      ourcart.cartItems?.reduce((acc, cur) => {
        return (
          acc +
          cur.varientId.salePrice * cur.quantity +
          cur?.addOns?.reduce((acc2, cur2) => {
            return acc2 + cur2.price * cur2.quantity;
          }, 0)
        );
      }, 0)
    );
  }, [ourcart]);

  useEffect(() => {
    findGst();
  }, [subTotal]);

  const findGst = () => {
    setGst((subTotal * admin.gstPercentage) / 100);
  };

  const placeOrderFunction = async (userAddress) => {
    setisLoading(true);
    const orderProducts = ourcart.cartItems.map((item) => {
      return {
        productName: item.product.productName,
        productId: item.product._id,
        varientId: item.varientId._id,
        salePrice: item.varientId.salePrice,
        quantity: item.quantity,
        addOns: item.addOns,
        subTotal:
          item.varientId.salePrice * item.quantity +
          item.addOns.reduce((acc, cur) => {
            return acc + cur.price * cur.quantity;
          }, 0),
      };
    });
    let orderData = {
      userId: userid.id,
      paymentMethod: selectedMethod,
      // paymentId: "",
      tableNo: params.table,
      tableIds: [params.tableId],
      noOfPax: userAddress.noOfPax ? userAddress.noOfPax : 1,
      grandTotal: subTotal + gst,
      isOrderForOthers: false,
      currentTime: new Date(),
      note: note,
      typeofOrder: "Dine in",
      gstPercentage: admin.gstPercentage,
      productsSubTotal: subTotal,
      gstAmount: gst,
      products: orderProducts,
      vendorId: admin._id,
      deliveryDetails: {
        name: userAddress.name,
        mobile: userAddress.number,
        // address: address.address,
      },
    };
    console.log(orderData);
    const res = await placeOrder(orderData);
    console.log(res);
    if (res.status === 200) {
      orderModalHandleOpen();
      setTimeout(() => {
        dispatch(clearCart());
        navigate("/");
      }, 2000);
    }
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  if (ourcart.cartItems?.length < 1) {
    navigate(`/?${createSearchParams(params)}`);
  }

  const [addrModalOpen, setAddrModalOpen] = useState(false);
  const addrModalhandleOpen = () => setAddrModalOpen(true);
  const addrModalhandleClose = () => setAddrModalOpen(false);

  const [deleteProductId, setFavProductId] = useState(null);
  const [removeItemModalOpen, setRemoveItemModalOpen] = useState(false);
  const removeItemModalhandleOpen = () => setRemoveItemModalOpen(true);
  const removeItemModalhandleClose = () => setRemoveItemModalOpen(false);

  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleRemoveFromCart = () => {
    dispatch(removeFromCart(deleteProductId));
  };

  const [couponNotAvilableModalOpen, setCouponNotAvilableModalOpen] =
    useState(false);
  const couponNotAvilableModalHandleOpen = () =>
    setCouponNotAvilableModalOpen(true);
  const couponNotAvilableModalHandleClose = () =>
    setCouponNotAvilableModalOpen(false);
  return (
    <Box className={classes.container}>
      <Box
        sx={{
          height: "65px",
          // height: "8%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Link to={`/?${createSearchParams(params)}`}>
          <ArrowBackIcon
            sx={{
              fontSize: "3rem",
              position: "absolute",
              left: 10,
              top: 20,
              color: "#000",
            }}
          />
        </Link>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
            Cart
          </Typography>
          <Typography
            sx={{
              fontSize: "1.3rem",
              fontWeight: "400",
              color: "rgba(173, 173, 173, 1)",
            }}
          >
            {ourcart.cartItems.length} Item
          </Typography>
        </Box>
      </Box>
      <Box sx={{ overflowY: "scroll", height: "82%" }}>
        <Box
          sx={{
            bgcolor: "#fff",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxSizing: "border-box",
            p: "1rem 2rem",
          }}
        >
          <Typography
            sx={{ fontSize: "1.4rem", fontWeight: "400", maxWidth: "80%" }}
          >
            Table No: <span style={{ fontWeight: "600" }}>{params.table}</span>{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "80px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            boxSizing: "border-box",
            p: "1rem 2rem",
          }}
        >
          <Button
            sx={{
              color: "rgba(255, 46, 46, 1)",
              width: "150px",
              height: "40px",
              fontSize: "1.1rem",
              textTransform: "none",
              fontSize: "1.5rem",
              borderRadius: "10px",
              "&:hover": {
                borderColor: "rgba(255, 46, 46, 1)",
              },
              borderColor: "rgba(255, 46, 46, 1)",
            }}
            variant="outlined"
          >
            Dine-in
          </Button>
        </Box>
        <Box
          sx={{
            bgcolor: "#fff",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            p: "1rem 2rem",
          }}
        >
          {ourcart.cartItems.map((item, index) => (
            <Box
              key={item?._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mt: "5px",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px" }}>
                <img
                  src={veg}
                  alt="veg"
                  style={{ width: "40px", height: "40px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: "8px",
                  }}
                >
                  <Typography sx={{ fontSize: "1.5rem", fontWeight: "400" }}>
                    {/* {item.varientId.productName} */}
                    {item.varientId.productName
                      .toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  </Typography>
                  {item.addOns.length ? (
                    <Box sx={{ display: "flex", gap: "2px" }}>
                      <Typography
                        sx={{ fontSize: "1.4rem", fontWeight: "400" }}
                      >
                        <span style={{ color: "red" }}>ADDON:</span>
                      </Typography>
                      <Box>
                        {item.addOns.map((addon) => (
                          <Typography
                            key={addon?._id}
                            sx={{ fontSize: "1.4rem", fontWeight: "400" }}
                          >
                            {addon.name
                              .toLowerCase()
                              .replace(/\b\w/g, (c) => c.toUpperCase())}
                            {/* {addon.name} */}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", mt: "3px" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      border: "1px solid red",
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "80px",
                      height: "30px",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      sx={{ color: "rgba(244, 0, 0, 1)", fontSize: "3rem" }}
                      onClick={() => {
                        if (item.quantity > 1) {
                          handleDecreaseCart(item);
                        } else {
                          setFavProductId(item);
                          removeItemModalhandleOpen();
                        }
                      }}
                    >
                      -
                    </Typography>
                    <Typography sx={{ fontSize: "2rem" }}>
                      {item.quantity}
                    </Typography>
                    <Typography
                      sx={{ color: "rgba(244, 0, 0, 1)", fontSize: "2rem" }}
                      onClick={() => handleAddToCart(item)}
                    >
                      +
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: "400",
                      textAlign: "end",
                      mt: "5px",
                    }}
                  >
                    <span style={{ fontSize: "1.2rem" }}>
                      {admin?.currencySymbol}&nbsp;
                    </span>
                    {item.varientId.salePrice * item.quantity +
                      item.addOns.reduce((acc, cur) => {
                        return acc + cur.price * cur.quantity;
                      }, 0)}
                  </Typography>
                </Box>
                <DeleteForeverIcon
                  sx={{
                    color: "red",
                    fontSize: "3rem",
                    alignSelf: "flex-start",
                  }}
                  onClick={() => {
                    setFavProductId(item);
                    removeItemModalhandleOpen();
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Accordion>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: "2.5rem" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ShortTextIcon sx={{ fontSize: "3rem" }} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
              Add Instruction
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              placeholder="Add your notes here"
              value={note}
              sx={{ width: "100%" }}
              multiline
              rows={4}
              inputProps={{ style: { fontSize: "1.7rem" } }}
              onChange={(e) => setNote(e.target.value)}
            />
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            mt: 2,
            bgcolor: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            boxSizing: "border-box",
            p: "1rem 2rem",
          }}
        >
          <Typography sx={{ fontSize: "1.7rem", fontWeight: "400", mb: 1 }}>
            Order Details ({ourcart.cartItems.length} Item)
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "1.7rem", fontWeight: "500" }}>
              Cart Total
            </Typography>
            <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
              <span style={{ fontSize: "1.4rem" }}>
                {admin?.currencySymbol}&nbsp;
              </span>
              {subTotal}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
              Coupon
            </Typography>
            <Typography
              sx={{ fontSize: "1.7rem", fontWeight: "500", color: "red" }}
              onClick={couponNotAvilableModalHandleOpen}
            >
              Apply Coupon
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
              {admin?.gstOrVat}
            </Typography>
            <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
              <span style={{ fontSize: "1.4rem" }}>
                {admin?.currencySymbol}&nbsp;
              </span>
              {gst}
            </Typography>
          </Box>
          <Divider />
        </Box>
      </Box>
      {/*  */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          bgcolor: "#fff",
          height: "11%",
          // height: "10%",
          width: "100%",
          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "rgba(159, 159, 159, 1)",
              fontSize: "1.2rem",
              fontWeight: "400",
            }}
          >
            Total
          </Typography>
          <Typography sx={{ fontSize: "2rem", fontWeight: "400" }}>
            <span style={{ fontSize: "1.6rem" }}>
              {admin?.currencySymbol}&nbsp;
            </span>
            {subTotal + gst}
          </Typography>
        </Box>
        {isLoading ? (
          <Button
            sx={{
              backgroundColor: "#FF2E2E",
              color: "#fff",
              width: "150px",
              height: "40px",
              fontSize: "1.1rem",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#FF2E2E",
              },
            }}
          >
            <CircularProgress color="inherit" size={25} />
          </Button>
        ) : (
          <Button
            sx={{
              backgroundColor: "#FF2E2E",
              color: "#fff",
              width: "150px",
              height: "40px",
              fontSize: "1.1rem",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#FF2E2E",
              },
            }}
            onClick={() => {
              // placeOrderFunction();
              addrModalhandleOpen();
            }}
          >
            Continue
          </Button>
        )}
      </Box>
      {/*  */}
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MinOrderValueModal
            admin={admin}
            subTotal={subTotal}
            handleClose={handleClose}
          />
        </Modal>
      </Box>
      {/*  */}
      <Box>
        <Drawer
          duration={250}
          hideScrollbars={true}
          onClose={addrModalhandleClose}
          isVisible={addrModalOpen}
        >
          <AddressModal
            placeOrderFunction={placeOrderFunction}
            grandTotal={subTotal + gst}
            closemodal={addrModalhandleClose}
          />
        </Drawer>
      </Box>
      <Box>
        <Modal
          open={orderModalOpen}
          // onClose={orderModalHandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <OrderPlacedSuccessModal />
        </Modal>
      </Box>
      <Box>
        <Modal
          open={removeItemModalOpen}
          onClose={removeItemModalhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <RemoveItem
            removeItemModalhandleClose={removeItemModalhandleClose}
            handleDeleteFavProduct={handleRemoveFromCart}
            head1={"product"}
          />
        </Modal>
      </Box>
      <Modal
        open={couponNotAvilableModalOpen}
        onClose={couponNotAvilableModalHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CouponNotAvilable close={couponNotAvilableModalHandleClose} />
      </Modal>
    </Box>
  );
}
