import "./App.css";
import { useEffect } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import Home from "./pages/Home";
import { useMediaQuery } from "@mui/material";
import Menu from "./pages/Menu";
import Orders from "./pages/Orders";
import Cart from "./pages/Cart";
import { getAdminDetails } from "./sevice/login";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Search from "./pages/Search";
import { ToastContainer } from "react-toastify";
import DesktopErrorPage from "./components/errorPages/DesktopErrorPage";
import ScanAgainErrorPage from "./components/errorPages/ScanAgainErrorPage";
import "react-toastify/dist/ReactToastify.css";

function App() {   
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const dispatch = useDispatch();
  const isTvScreen = useMediaQuery("(max-width: 550px)");
  useEffect(() => {
    findAdminAddressData();
  }, []);
  const findAdminAddressData = async () => {
    const res = await getAdminDetails();
    if (res.data) {
      dispatch({ type: "ADMINDATA", payload: res.data });
      Cookies.set("admin", JSON.stringify(res.data));
    }
  };

  return (
    <>
      <ToastContainer />
      {!params.id || !params.table || !params.tableId ? (
        <ScanAgainErrorPage />
      ) : isTvScreen ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu/:categoryId" element={<Menu />} />
          <Route path="/search" element={<Search />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/error_page" element={<ScanAgainErrorPage />} />
        </Routes>
      ) : (
        <DesktopErrorPage />
      )}
    </>
  );
}

export default App;
