import Cookies from "js-cookie";

export function UserReducer(
  state = Cookies.get("userid") ? JSON.parse(Cookies.get("userid")) : null,
  action
) {
  switch (action.type) {
    case "USERID":
      return action.payload;
    default:
      return state;
  }
}
