import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { adressSchema } from "../../schemas";
import { loginWithPhone } from "../../sevice/login";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useState } from "react";

const initialValues = {
  number: "",
  name: "",
  noOfPax: "",
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default function AddressModal({
  placeOrderFunction,
  grandTotal,
  closemodal,
}) {
  const { admin } = useSelector((state) => state);
  const [limit, setLimit] = useState({
    min: admin?.country === "UAE" || admin?.country === "KSA" ? 9 : 10,
    max: admin?.country === "UAE" || admin?.country === "KSA" ? 12 : 10,
  });

  const adressSchema = Yup.object({
    number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(limit.min, `Minimum ${limit.min} numbers is required !`)
      .max(limit.max, `Maximum ${limit.max} numbers is allowed!`)
      .typeError("field must be a number")
      .required("Number is requird"),
    name: Yup.string().required("Name is required"),
    noOfPax: Yup.number()
      // .min(1, "Too Short!")
      .typeError("Field must be a number"),
    // .required("Number of pax is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adressSchema,
    onSubmit: async (values, action) => {
      placeOrderFunction(values);
      closemodal();
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            height: "auto",
            margin: "0rem 10px 2rem 10px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography sx={{ fontSize: "1.8rem", fontWeight: "400" }}>
            Enter your details
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Enter Your Name"
              FormHelperTextProps={{ style: { fontSize: "1.5rem" } }}
              inputProps={{ style: { fontSize: "1.5rem" } }} // font size of input text
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={!!formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              size="small"
              variant="outlined"
              placeholder="Enter Your Number"
              inputProps={{ style: { fontSize: "1.5rem" } }} // font size of input text
              FormHelperTextProps={{ style: { fontSize: "1.5rem" } }}
              type="number"
              name="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              error={!!formik.touched.number && !!formik.errors.number}
              helperText={formik.touched.number && formik.errors.number}
            />
            <TextField
              size="small"
              variant="outlined"
              placeholder="Number of pax"
              inputProps={{ style: { fontSize: "1.5rem" } }} // font size of input text
              FormHelperTextProps={{ style: { fontSize: "1.5rem" } }}
              type="number"
              name="noOfPax"
              value={formik.values.noOfPax}
              onChange={formik.handleChange}
              error={!!formik.touched.noOfPax && !!formik.errors.noOfPax}
              helperText={formik.touched.noOfPax && formik.errors.noOfPax}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{ color: "#B2BEB5", fontSize: "1.5rem", fontWeight: "400" }}
              >
                Total
              </Typography>
              <Typography sx={{ fontSize: "1.8rem", fontWeight: "400" }}>
                {grandTotal}
              </Typography>
            </Box>
            <Button
              sx={{
                backgroundColor: "#FF2E2E",
                color: "#fff",
                height: "45px",
                borderRadius: "10px",
                fontSize: "1.3rem",
                fontWeight: "400",
                "&:hover": {
                  backgroundColor: "#FF2E2E",
                },
                textTransform: "none",
              }}
              type="submit"
            >
              Place Order
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
}
