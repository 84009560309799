import { useState, useCallback, useEffect } from "react";
import {
  Link,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import veg from "../assets/veg.svg";
import nonVeg from "../assets/nonVeg.svg";
import StarIcon from "@mui/icons-material/Star";
import Drawer from "react-bottom-drawer";
import DrawerContent from "../components/common/AddItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import MenuModal from "../components/menu/MenuModal";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100dvw",
    boxSizing: "border-box",
    padding: "2.5rem 1.5rem 2.5rem 1.5rem",
    display: "flex",
    flexDirection: "column",
  },
}));

export default function Menu() {
  const { admin } = useSelector((state) => state);
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState(Object.fromEntries([...searchParams]));
  const { ourproductsdata } = useSelector((state) => state);
  const [filterdProduct, setFilterdProduct] = useState([]);

  const [items, setItems] = useState([]);
  const fetchItems = async () => {
    const productsWithTargetId = ourproductsdata.filter((product) => {
      return product.groups.some((group) => group.group._id === categoryId);
    });
    setItems(productsWithTargetId);
    setFilterdProduct(productsWithTargetId);
  };

  useEffect(() => {
    fetchItems();
  }, [categoryId]);

  const addtoCart = (item) => {
    openDrawer(item);
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback((item) => {
    setSelectedItem(item);
    setIsVisible(true);
  }, []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const filterVegAndNonVeg = (value) => {
    const data = items.filter((item) => item.vegOrNonVeg === value);
    setFilterdProduct(data);
  };
  const filterBestSeller = () => {
    const data = items.filter((item) => item.bestSeller);
    setFilterdProduct(data);
  };

  return (
    <Box className={classes.container}>
      <Box
        sx={{
          bgcolor: "#E9E9E9",
          width: "100%",
          height: "50px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
          px: "2rem",
        }}
      >
        <Link to={`/?${createSearchParams(params)}`}>
          <ArrowBackIcon sx={{ fontSize: "3rem", color: "#92929B" }} />
        </Link>
        <TextField
          variant="outlined"
          placeholder="Search items"
          sx={{
            "& fieldset": { border: "none" },
            width: "100%",
          }}
          onClick={() => navigate(`/search?${createSearchParams(params)}`)}
          inputProps={{ style: { fontSize: "1.7rem" } }}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "8px", py: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            p: "3px",
            border: "1px solid #9F9F9F",
            borderRadius: "5px",
          }}
          onClick={() => filterVegAndNonVeg("Veg")}
        >
          <img src={veg} alt="veg" />
          <Typography sx={{ fontSize: "1.6rem", px: 1 }}>Veg</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            p: "3px",
            border: "1px solid #9F9F9F",
            borderRadius: "5px",
          }}
          onClick={() => filterVegAndNonVeg("NonVeg")}
        >
          <img src={nonVeg} alt="nonVeg" />
          <Typography sx={{ fontSize: "1.6rem", px: 1 }}>Non-veg</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            p: "3px",
            border: "1px solid #9F9F9F",
            borderRadius: "5px",
          }}
          onClick={() => filterBestSeller()}
        >
          <Typography sx={{ fontSize: "1.6rem", px: 1 }}>Bestseller</Typography>
        </Box>
      </Box>
      <Typography sx={{ fontSize: "2rem", pb: 2 }}>Recommended</Typography>
      <Box sx={{ height: "auto", overflowY: "scroll" }}>
        {filterdProduct?.map((item) => (
          <Box
            sx={{
              height: "210px",
              display: "flex",
              justifyContent: "space-between",
            }}
            key={item._id}
            onClick={() => addtoCart(item)}
          >
            <Box>
              <Box sx={{ display: "flex", gap: "5px" }}>
                {item.vegOrNonVeg === "Veg" ? (
                  <img src={veg} alt="nonVeg" />
                ) : (
                  <img src={nonVeg} alt="nonVeg" />
                )}
                {item.bestSeller && (
                  <Box
                    sx={{
                      bgcolor: "orange",
                      color: "white",
                      px: 1,
                      borderRadius: "3px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Bestseller</Typography>
                  </Box>
                )}
              </Box>
              <Typography sx={{ fontSize: "2rem" }}>
                {item.title
                  .toLowerCase()
                  .replace(/\b\w/g, (c) => c.toUpperCase())}
              </Typography>
              <Typography sx={{ fontSize: "2rem" }}>
                <span style={{ fontSize: "1.5rem" }}>
                  {admin?.currencySymbol}{" "}
                </span>
                {Math.max.apply(
                  null,
                  item?.varients?.map((item) => item?.varientId?.salePrice)
                )}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                <StarIcon sx={{ color: "#D1D1D1", fontSize: "2rem" }} />{" "}
                <Typography
                  sx={{ color: "#FE9E00", fontSize: "1.5rem", px: 1 }}
                >
                  4.0
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#9D9D9D", fontSize: "1.6rem" }}
                // onClick={() => addtoCart(item)}
              >
                View More
              </Typography>
            </Box>
            <Box
              sx={{
                width: "125px",
                height: "160px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
            >
              <img
                src={item.varients[0].varientId.image}
                alt="biriyani"
                style={{
                  objectFit: "cover",
                  width: "125px",
                  height: "125px",
                  borderRadius: "5px",
                }}
              />
              <Button
                sx={{
                  backgroundColor: "#FF2E2E",
                  color: "#fff",
                  width: "90px",
                  fontSize: "1.1rem",
                  borderRadius: "10px",
                  position: "absolute",
                  bottom: 20,
                  "&:hover": {
                    backgroundColor: "#FF2E2E",
                  },
                }}
                className="open-btn"
                // onClick={() => addtoCart(item)}
              >
                ADD
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          bgcolor: "#000",
          position: "fixed",
          p: 2,
          borderRadius: "15px",
          display: "flex",
          alignItems: "center",
          gap: "20px",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: "1",
        }}
        onClick={handleOpen}
      >
        <AutoStoriesIcon sx={{ color: "#fff", fontSize: "3rem" }} />
        <Typography sx={{ fontSize: "2rem", color: "#fff" }}>Menu</Typography>
      </Box>

      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MenuModal handleClose={handleClose} />
        </Modal>
      </Box>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
      >
        <DrawerContent closeDrawer={closeDrawer} selectedItem={selectedItem} />
      </Drawer>
    </Box>
  );
}
