import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";

export default function CategoryCard({ category }) {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState(Object.fromEntries([...searchParams]));
  return (
    <Link
      to={`/menu/${category.id}?${createSearchParams(params)}`}
      key={category.id}
      style={{ textDecoration: "none", color: "#000" }}
    >
      <Box
        key={category.id}
        sx={{
          width: "115px",
          height: "150px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          flexShrink: 0,
          boxSizing: "border-box",
          textAlign: "center",
        }}
      >
        <img
          src={category.image}
          style={{
            objectFit: "cover",
            width: "90px",
            minHeight: "90px",
            maxHeight: "90px",
            borderRadius: "10px",
          }}
          alt="category"
        />
        <Typography
          sx={{
            fontSize: "1.3rem",
            fontWeight: "400",
          }}
        >
          {category.groupName
            .toLowerCase()
            .replace(/\b\w/g, (c) => c.toUpperCase())}
          {/* {category.groupName} */}
        </Typography>
      </Box>
    </Link>
  );
}
