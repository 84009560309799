import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "45vh",
  bgcolor: "#000",
  borderRadius: "20px",
  boxShadow: 24,
  p: "4rem 1rem",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
};
export default function QuickMenu({ categories }) {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState(Object.fromEntries([...searchParams]));
  return (
    <Box sx={style}>
      <Box
        sx={{
          bgcolor: "#000",
          position: "fixed",
          p: 2,
          borderRadius: "50%",
          top: "-35px",
          right: "35vw",
          zIndex: "1",
        }}
      >
        <AutoStoriesIcon sx={{ color: "#fff", fontSize: "3rem" }} />
      </Box>
      <Typography
        sx={{
          fontSize: "2rem",
          fontWeight: "400",
          color: "#fff",
          alignSelf: "center",
        }}
      >
        Quick Menu
      </Typography>
      <Box
        sx={{
          mt: 2,
          height: "auto",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar
          },
        }}
      >
        <Grid container>
          {categories.map((item, i) => (
            <Grid item xs={4} sm={3}>
              <Link
                to={`/menu/${item.id}?${createSearchParams(params)}`}
                key={item.id}
                style={{ textDecoration: "none", color: "#000" }}
              >
                <Box
                  sx={{
                    position: "relative",
                    backgroundColor: "rgba(84, 84, 84, 1)",
                    color: "#fff",
                    maxWidth: "85px",
                    height: "85px",
                    borderRadius: "10px",
                    boxSizing: "border-box",
                    m: "auto",
                    my: 1,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.4rem",
                      fontWeight: "400",
                      position: "absolute",
                      textAlign: "center",
                      mx: 0.5,
                    }}
                  >
                    {item.groupName
                      .toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                    {/* {item.groupName} */}
                  </Typography>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
