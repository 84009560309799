import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;
const adminId = new URLSearchParams(window.location.search).get("id");

export const placeOrder = async (value) => {
  try {
    const data = await axios.post(`${host}/placeOrder`, value);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const userOrderDetails = async (userId) => {
  try {
    const { data } = await axios.get(
      `${host}/userOrderDetails/${userId}/${adminId}`
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const userOrderHistory = async (userId) => {
  try {
    const { data } = await axios.get(
      `${host}/userOrderHistory/${userId}/${adminId}`
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const cancelOrder = async (orderId) => {
  try {
    const data = await axios.put(`${host}/cancelOrder/${orderId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
