import qrError from "../../lottie/qr.json";
import Lottie from "lottie-react";
import { Box, Typography } from "@mui/material";

export default function ScanAgainErrorPage() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Box sx={{ width: "200px", height: "200px", alignSelf: "center" }}>
        <Lottie animationData={qrError} />
      </Box>
      <Typography
        sx={{ fontSize: "1.4rem", textAlign: "center", color: "red" }}
      >
        No table found, Please scan the QR Again
      </Typography>
    </Box>
  );
}
