import { createSlice } from "@reduxjs/toolkit";
const adminId = new URLSearchParams(window.location.search).get("id");

const initialState = {
  cartItems: localStorage.getItem("ourcartItems")
    ? JSON.parse(localStorage.getItem("ourcartItems"))
    : [],
  adminId: localStorage.getItem("adminId")
    ? JSON.parse(localStorage.getItem("adminId"))
    : null,
};
const cartSlice = createSlice({
  name: "ourcart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const existingIndex = state.cartItems.findIndex(
        (item) => item.varientId._id === action.payload.varientId._id
      );
      if (existingIndex >= 0) {
        state.cartItems[existingIndex] = {
          ...state.cartItems[existingIndex],
          quantity: state.cartItems[existingIndex].quantity + 1,
        };
      } else {
        state.cartItems.push(action.payload);
        state.adminId = adminId;
      }
      localStorage.setItem("ourcartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("adminId", JSON.stringify(state.adminId));
    },
    decreaseCart(state, action) {
      const existingIndex = state.cartItems.findIndex(
        (item) => item.varientId._id === action.payload.varientId._id
      );
      if (state.cartItems[existingIndex].quantity > 1) {
        state.cartItems[existingIndex].quantity -= 1;
      } else if (state.cartItems[existingIndex].quantity === 1) {
        state.cartItems.splice(existingIndex, 1);
      }
      localStorage.setItem("ourcartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      const existingIndex = state.cartItems.findIndex(
        (item) => item.varientId._id === action.payload.varientId._id
      );
      if (existingIndex >= 0) {
        state.cartItems.splice(existingIndex, 1);
      }
    },
    clearCart(state, action) {
      state.cartItems.splice(0, state.cartItems.length);
      state.adminId = null;
      localStorage.setItem("ourcartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("adminId", JSON.stringify(state.adminId));
    },
  },
});

export const { addToCart, decreaseCart, removeFromCart, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;
