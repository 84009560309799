import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;
const adminId = new URLSearchParams(window.location.search).get("id");

export const getAdminDetails = async () => {
  try {
    const data = await axios.get(`${host}/getAdminDetails/${adminId}`);
    return data;
  } catch (error) {
    return error.response;
  }
};
