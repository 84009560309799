import * as Yup from "yup";

export const adressSchema = Yup.object({
  number: Yup.string()
    .min(10, "Too Short!")
    .typeError("field must be a number")
    .required("Number is required"),
  name: Yup.string().required("Name is required"),
  noOfPax: Yup.number()
    .min(1, "Too Short!")
    .typeError("Field must be a number"),
  // .required("Number of pax is required"),
});
