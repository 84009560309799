import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  Link,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { Box, Modal, Skeleton, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Carousel from "react-material-ui-carousel";
import Drawer from "react-bottom-drawer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import SearchIcon from "@mui/icons-material/Search";
import DrawerContent from "../components/common/AddItem";
import "../App.css";
import {
  fetchCategories,
  fetchMainBanner,
  fetchSubBanner,
} from "../sevice/home";
import QuickMenuModal from "../components/home/QuickMenuModal";
import Footer from "../components/home/Footer";
import BestsellerCard from "../components/home/BestsellerCard";
import ExclusiveDishes from "../components/home/ExclusiveDishes";
import CategoryCard from "../components/home/CategoryCard";
import { userOrderDetails } from "../sevice/order";
import OrdersCard from "../components/home/OrdersCard";
import { allProducts } from "../sevice/search";
import Cookies from "js-cookie";
import CategoryCardSkeleton from "../components/home/CategoryCardSkeleton";
import BestsellerCardSkeleton from "../components/home/BestsellerCardSkeleton";
import uuid from "react-uuid";
import { clearCart } from "../store/cartslice";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    width: "100vw",
    boxSizing: "border-box",
    padding: "2rem 1.5rem 2.5rem 1.5rem",
  },
}));

export default function Home() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState(Object.fromEntries([...searchParams]));
  const { userid } = useSelector((state) => state);
  const { ourcart } = useSelector((state) => state);
  const { ourmainbanners } = useSelector((state) => state);
  const { ourcategories } = useSelector((state) => state);
  const { ourproductsdata } = useSelector((state) => state);
  const { admin } = useSelector((state) => state);
  const [bestSellers, setBestSellers] = useState([]);
  const [subBanner, setsubBanner] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [randomlyPickedItem, setRandomlyPickedItem] = useState();

  const findCategories = async () => {
    const res = await fetchCategories();
    dispatch({ type: "OURCATEGORIES", payload: res });
    sessionStorage.setItem("ourcategories", JSON.stringify(res));
  };

  const findMainBanners = async () => {
    const res = await fetchMainBanner();
    dispatch({ type: "OURMAINBANNERS", payload: res });
    Cookies.set("ourmainbanners", JSON.stringify(res));
  };
  const findSubBanners = async () => {
    const res = await fetchSubBanner();
    setsubBanner(res);
  };

  useEffect(() => {
    findCategories();
    findMainBanners();
    findSubBanners();
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState(null);
  const [isVisibleProduct, setIsVisibleProduct] = useState(false);
  const openProductDetails = (item) => {
    openProductDrawer(item);
  };
  const openProductDrawer = useCallback((item) => {
    setSelectedItem(item);
    setIsVisibleProduct(true);
  }, []);
  const closeProductDrawer = useCallback(() => setIsVisibleProduct(false), []);

  useEffect(() => {
    findExclusiveDishes();
  }, [bestSellers]);

  const findExclusiveDishes = () => {
    const randomIndex = Math?.floor(Math?.random() * bestSellers?.length);
    setRandomlyPickedItem(bestSellers[randomIndex]);
  };

  useEffect(() => {
    findUserId();
  }, []);
  const findUserId = () => {
    if (!userid) {
      const id = uuid();
      dispatch({ type: "USERID", payload: { id: id } });
      Cookies.set("userid", JSON.stringify({ id: id }));
    }
  };

  useEffect(() => {
    finduserOrderDetails();
  }, [userid]);
  const finduserOrderDetails = async () => {
    console.log(userid);
    if (userid) {
      const res = await userOrderDetails(userid?.id);
      setOrderItems(res);
    }
  };

  useEffect(() => {
    findAllProducts();
  }, []);
  useEffect(() => {
    findBestSellers();
    handleClearCart();
  }, [ourproductsdata]);

  const dispatch = useDispatch();
  const findAllProducts = async () => {
    const res = await allProducts();

    if (res.length) {
      dispatch({ type: "OURPRODUCTSDATA", payload: res });
      localStorage.setItem("ourproductsdata", JSON.stringify(res));
    }
  };
  const findBestSellers = async () => {
    const bestSellers = ourproductsdata.filter(
      (product) => product.bestSeller === true
    );
    setBestSellers(bestSellers);
  };

  const handleClearCart = () => {
    if (params.id !== ourcart.adminId) {
      dispatch(clearCart());
    }
  };
  return (
    <Box>
      <Box className={classes.container}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: "1rem",
            pl: "1rem",
          }}
        >
          <Box
            sx={{
              height: "77px",
              width: "77px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {admin?.logo ? (
              <img
                src={admin?.logo}
                alt="logo"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"100%"}
                sx={{ borderRadius: "10px" }}
              />
            )}
          </Box>
          {ourcart.cartItems?.length ? (
            <Link
              to={`/cart?${createSearchParams(params)}`}
              style={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  bgcolor: "#000",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  p: "0.5rem 1rem",
                  borderRadius: "8px",
                }}
              >
                <ShoppingCartIcon sx={{ fontSize: "2.5rem" }} />
                <Typography sx={{ fontSize: "1.6rem", fontWeight: "400" }}>
                  {ourcart.cartItems?.length}
                </Typography>
              </Box>
            </Link>
          ) : (
            ""
          )}
        </Box>
        <Link
          to={`/search?${createSearchParams(params)}`}
          style={{ textDecoration: "none", color: "#000" }}
        >
          <Box
            sx={{
              bgcolor: "#F2F3F8",
              width: "100%",
              height: "50px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              boxSizing: "border-box",
              px: "2rem",
            }}
          >
            <SearchIcon sx={{ fontSize: "3rem", color: "#92929B" }} />
            <TextField
              variant="outlined"
              placeholder="Search items"
              sx={{
                "& fieldset": { border: "none" },
                width: "100%",
              }}
              inputProps={{ style: { fontSize: "1.7rem" } }}
            />
          </Box>
        </Link>
        <Box
          sx={{
            boxSizing: "border-box",
            p: "2rem 0rem 0rem 0rem",
            height: "27vh",
          }}
        >
          {ourmainbanners.length ? (
            <Carousel
              animation="slide"
              navButtonsAlwaysVisible={false}
              interval={3000}
              indicators={true}
              sx={{
                maxHeight: "24vh",
                borderRadius: "20px",
              }}
              autoPlay
            >
              {ourmainbanners?.map((img) => (
                <img
                  key={img._id}
                  style={{
                    width: "100%",
                    height: "20vh",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                  src={img.image}
                  alt="banner"
                  onClick={() => {
                    if (img.product) {
                      let findProducts = ourproductsdata.find(
                        (item) => item._id == img.item
                      );
                      openProductDetails(findProducts);
                    } else {
                      navigate(
                        `/menu/${img.item}?${createSearchParams(params)}`
                      );
                    }
                  }}
                />
              ))}
            </Carousel>
          ) : (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={"20vh"}
              sx={{ borderRadius: "20px" }}
            />
          )}
        </Box>
        <Typography
          sx={{ fontSize: "2rem", fontWeight: "400", lineHeight: "3rem" }}
        >
          Chooose by category
        </Typography>
        {ourcategories.length ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowX: "auto",
            }}
          >
            {[...Array(Math.ceil(ourcategories?.length / 5))]?.map(
              (item, i) => (
                <Box
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    mt: "5px",
                    "&::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  {ourcategories
                    ?.slice(i * 5, (i + 1) * 5)
                    .map((category, idx) => (
                      <CategoryCard key={idx} category={category} />
                    ))}
                </Box>
              )
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowX: "auto",
            }}
          >
            {[...Array(Math.ceil(10 / 5))]?.map((item, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  overflowX: "auto",
                  mt: "5px",
                  "&::-webkit-scrollbar": { display: "none" },
                }}
              >
                {[...Array(Math.ceil(10))]
                  ?.slice(i * 5, (i + 1) * 5)
                  .map((_, index) => (
                    <CategoryCardSkeleton index={index} />
                  ))}
              </Box>
            ))}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            gap: "1.5rem",
            overflowX: "auto",
            boxSizing: "border-box",
            my: "1rem",
            "&::-webkit-scrollbar": { display: "none" },
            px: "0.2rem",
            py: "0.2rem",
          }}
        >
          {bestSellers.length ? (
            bestSellers.map((item, idx) => (
              <BestsellerCard
                key={idx}
                item={item}
                openProductDetails={openProductDetails}
              />
            ))
          ) : (
            <>
              {[...Array(Math.ceil(10))].map((_, index) => (
                <BestsellerCardSkeleton key={index} index={index} />
              ))}
            </>
          )}
        </Box>
        <Box
          sx={{
            boxSizing: "border-box",
            p: "0.8rem 0rem 0rem 0rem",
            height: "20vh",
          }}
        >
          {subBanner.length ? (
            <img
              src={subBanner[0]?.image}
              style={{
                width: "100%",
                maxHeight: "20vh",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              alt="refreshments"
              onClick={() => {
                if (subBanner[0]?.product) {
                  let findProducts = ourproductsdata.find(
                    (item) => item._id == subBanner[0].item
                  );
                  openProductDetails(findProducts);
                } else {
                  navigate(
                    `/menu/${subBanner[0]?.item}?${createSearchParams(params)}`
                  );
                }
              }}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={"20vh"}
              sx={{ borderRadius: "10px" }}
            />
          )}
        </Box>
        <ExclusiveDishes
          randomlyPickedItem={randomlyPickedItem}
          openProductDetails={openProductDetails}
        />
        <Typography sx={{ fontSize: "2.2rem", fontWeight: "500" }}>
          Chef's Choice
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1.5rem",
            overflowX: "auto",
            boxSizing: "border-box",
            my: "1rem",
            "&::-webkit-scrollbar": { display: "none" },
            px: "0.2rem",
            py: "0.2rem",
          }}
        >
          {bestSellers.length ? (
            bestSellers
              .reverse()
              .map((item) => (
                <BestsellerCard
                  key={item?._id}
                  item={item}
                  openProductDetails={openProductDetails}
                />
              ))
          ) : (
            <>
              {[...Array(Math.ceil(10))].map((_, index) => (
                <BestsellerCardSkeleton key={index} index={index} />
              ))}
            </>
          )}
        </Box>
        <Footer admin={admin} />
        <Box
          sx={{
            boxSizing: "border-box",
            alignSelf: "flex-end",
            position: "fixed",
            bottom: orderItems.length ? "175px" : "5vh",
            right: "15px",
            zIndex: "1",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              bgcolor: "#000",
              alignSelf: "flex-end",
              p: 2,
              width: "30px",
              height: "30px",
              borderRadius: "50%",
            }}
            onClick={handleOpen}
          >
            <AutoStoriesIcon sx={{ color: "#fff", fontSize: "3rem" }} />
          </Box>
        </Box>
        {orderItems.length ? (
          <Box
            sx={{
              boxSizing: "border-box",
              width: "92%",
              position: "fixed",
              bottom: "20px",
              zIndex: "1",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <OrdersCard item={orderItems} admin={admin} />
          </Box>
        ) : (
          ""
        )}

        <Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <QuickMenuModal categories={ourcategories} />
          </Modal>
        </Box>
        <Drawer
          duration={250}
          hideScrollbars={true}
          onClose={closeProductDrawer}
          isVisible={isVisibleProduct}
        >
          <DrawerContent
            closeDrawer={closeProductDrawer}
            selectedItem={selectedItem}
          />
        </Drawer>
      </Box>
      <Box sx={{ height: orderItems.length ? "140px" : "" }}></Box>
    </Box>
  );
}
