import { Box, Divider, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "auto",
  bgcolor: "#fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: "2rem 2rem",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
};

export default function CancelOrderModal({ handleClose, cancelOrder }) {
  return (
    <Box sx={style}>
      <CancelOutlinedIcon
        sx={{
          color: "#F04141",
          fontSize: "5rem",
          marginInline: "auto",
        }}
      />
      <Typography
        sx={{
          fontSize: "1.7rem",
          fontWeight: "500",
          marginInline: "auto",
          textAlign: "center",
          my: 2,
        }}
      >
        Cancel Order?
      </Typography>
      <Typography
        sx={{
          fontSize: "1.3rem",
          fontWeight: "400",
          color: "#AFAFAF",
          marginInline: "auto",
          textAlign: "center",
        }}
      >
        Are You sure You want to cancel the order?
      </Typography>
      <Divider sx={{ mt: 2 }} />
      <Box sx={{ display: "flex" }}>
        <Typography
          onClick={handleClose}
          sx={{
            color: "#F04141",
            marginInline: "auto",
            mt: 2,
            fontSize: "1.5rem",
            fontWeight: "400",
          }}
        >
          Cancel
        </Typography>
        <Typography
          onClick={() => {
            cancelOrder();
            handleClose();
          }}
          sx={{
            color: "#C5C5C5",
            marginInline: "auto",
            mt: 2,
            fontSize: "1.5rem",
            fontWeight: "400",
          }}
        >
          Yes
        </Typography>
      </Box>
    </Box>
  );
}
