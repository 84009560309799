import monitorError from "../../assets/monitorError.png";
import { Box, Typography } from "@mui/material";

export default function DesktopErrorPage() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
        background: "#351311",
      }}
    >
      <img src={monitorError} alt="mobile error" style={{ width: "50vw" }} />
      <Typography
        sx={{
          color: "white",
          fontSize: "2rem",
          marginTop: 2,
          marginRight: 3,
          marginLeft: 3,
          textAlign: "center",
          fontFamily: "poppins",
        }}
      >
        Desktop Preview is not available; please open in a mobile browser.
      </Typography>
    </Box>
  );
}
