import { combineReducers } from "redux";
import { UserReducer } from "./reducers/UserReducer";
import { AdminReducer } from "./reducers/AdminReducer";
import { ProductsReducer } from "./reducers/ProductsReducer";
import cartReducer from "./cartslice";
import { OurMainBannersReducer } from "./reducers/MainBanner";
import { OurCategoriesReducer } from "./reducers/CategoriesReducer";

const rootReducer = combineReducers({
  userid: UserReducer,
  admin: AdminReducer,
  ourproductsdata: ProductsReducer,
  ourcart: cartReducer,
  ourmainbanners: OurMainBannersReducer,
  ourcategories: OurCategoriesReducer,
});

export default rootReducer;
