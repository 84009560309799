import { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Drawer from "react-bottom-drawer";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback } from "react";
import DrawerContent from "../components/common/AddItem";
// import LoginPromptDrawer from "../components/login/LoginPrompt";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100vw",
    boxSizing: "border-box",
    padding: "2rem 1.5rem 2.5rem 1.5rem",
  },
}));

export default function Search() {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState(Object.fromEntries([...searchParams]));
  const { ourproductsdata } = useSelector((state) => state);
  const { ourcart } = useSelector((state) => state);
  const { user } = useSelector((state) => state);
  const { admin } = useSelector((state) => state);
  const [productSearch, setProductSearch] = useState("");

  function handleSearch(event) {
    setProductSearch(event.target.value);
  }

  const handleClearSearch = () => {
    setProductSearch("");
  };

  const filteredProducts = productSearch
    ? ourproductsdata?.filter((product) =>
        product.title.toLowerCase().includes(productSearch.toLowerCase())
      )
    : [];

  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState(null);
  const [isVisibleProduct, setIsVisibleProduct] = useState(false);
  const openProductDrawer = useCallback((item) => {
    setSelectedItem(item);
    setIsVisibleProduct(true);
  }, []);

  const closeProductDrawer = useCallback(() => setIsVisibleProduct(false), []);

  return (
    <Box className={classes.container}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: "1rem",
          pl: "1rem",
        }}
      >
        <Box sx={{ height: "77px", width: "77px" }}>
          <img
            src={admin.logo}
            alt="logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Link to={`/cart?${createSearchParams(params)}`}>
          {ourcart.cartItems?.length ? (
            <Box
              sx={{
                bgcolor: "#000",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                p: "0.5rem 1rem",
                borderRadius: "8px",
              }}
            >
              <ShoppingCartIcon sx={{ fontSize: "2.5rem" }} />
              <Typography sx={{ fontSize: "1.6rem", fontWeight: "400" }}>
                {ourcart.cartItems?.length}
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Link>
      </Box>
      <Box
        sx={{
          bgcolor: "#F2F3F8",
          width: "100%",
          height: "50px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
          px: "2rem",
        }}
      >
        <Link to={-1}>
          <ArrowBackIcon sx={{ fontSize: "3rem", color: "#92929B" }} />
        </Link>
        <TextField
          variant="outlined"
          placeholder="Search for item"
          sx={{
            "& fieldset": { border: "none" },
            width: "100%",
          }}
          inputProps={{ style: { fontSize: "1.7rem" } }}
          value={productSearch}
          onChange={handleSearch}
        />
        <CloseIcon
          sx={{ fontSize: "2rem", color: "#92929B" }}
          onClick={handleClearSearch}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "75vh",
          overflowY: "scroll",
          mt: 2,
        }}
      >
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <Box
              key={product?._id}
              sx={{
                mt: 2,
                display: "flex",
                gap: "1rem",
                boxSizing: "border-box",
              }}
              onClick={() => openProductDrawer(product)}
            >
              <Box
                sx={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  bgcolor: "beige",
                }}
              >
                <img
                  src={product.varients[0].varientId.image}
                  alt="product"
                  style={{
                    objectFit: "cover",
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  key={product._id}
                  sx={{ fontSize: "2rem", fontWeight: "400" }}
                >
                  {/* {product.title} */}
                  {product.title
                    .toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Typography>
                <Typography
                  sx={{ fontSize: "1.4rem", fontWeight: "400", color: "blue" }}
                >
                  In{" "}
                  {product.category?.category
                    .toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                  {/* {product.category?.category} */}
                </Typography>
              </Box>
              <ArrowOutwardIcon
                sx={{
                  fontSize: "3rem",
                  marginLeft: "auto",
                  alignSelf: "center",
                }}
              />
            </Box>
          ))
        ) : (
          <Typography sx={{ mt: 2, fontSize: "1.5rem" }}>
            No products found
          </Typography>
        )}
      </Box>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeProductDrawer}
        isVisible={isVisibleProduct}
      >
        <DrawerContent
          closeDrawer={closeProductDrawer}
          selectedItem={selectedItem}
        />
      </Drawer>
    </Box>
  );
}
